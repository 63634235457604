// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bedankt-js": () => import("../src/pages/bedankt.js" /* webpackChunkName: "component---src-pages-bedankt-js" */),
  "component---src-pages-contacteer-ons-js": () => import("../src/pages/contacteer-ons.js" /* webpackChunkName: "component---src-pages-contacteer-ons-js" */),
  "component---src-pages-faq-js": () => import("../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-feest-organiseren-js": () => import("../src/pages/feest-organiseren.js" /* webpackChunkName: "component---src-pages-feest-organiseren-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sfeer-js": () => import("../src/pages/sfeer.js" /* webpackChunkName: "component---src-pages-sfeer-js" */),
  "component---src-pages-waarom-ritorno-js": () => import("../src/pages/waarom-ritorno.js" /* webpackChunkName: "component---src-pages-waarom-ritorno-js" */)
}

